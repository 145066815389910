<template>
  <div class="center">
    <div class="header_title">服务商信息</div>
    <div class="company">
      <div class="name">{{ alldata.consumerName }}</div>
      <div class="tell">
        <i class="hy-icon-dianhua"></i> {{ alldata.consumerTelephone }}
      </div>
    </div>
    <div class="header_title">订单信息</div>
    <div class="order">
      <div class="left">
        <img src="@/assets/images/dingdan.jpg" />
      </div>
      <div class="table">
        <el-descriptions class="margin-top" :column="3" size="medium" border>
          <el-descriptions-item :span="3" label-class-name="my-label">
            <template slot="label"> 状态： </template>
            {{ applyStatus[alldata.applyStatus] }}
          </el-descriptions-item>
          <el-descriptions-item :span="3" label-class-name="my-label">
            <template slot="label"> 订单创建时间： </template>
            {{ alldata.createTime }}
          </el-descriptions-item>
          <el-descriptions-item
            v-if="alldata.applyStatus != 0"
            :span="3"
            label-class-name="my-label"
          >
            <template slot="label">
              订单{{ alldata.applyStatus == 1 ? "通过" : "拒绝" }}时间：
            </template>
            {{ applydata.auditTime.replace("T", " ") }}
          </el-descriptions-item>
        </el-descriptions>
      </div>
    </div>
    <div class="header_title">
      授信信息<span v-if="alldata.applyStatus != 1" class="red_title">
        ({{ applyStatus[alldata.applyStatus] }}状态下暂无授权相关信息)
      </span>
    </div>
    <div class="car">
      <el-descriptions
        v-if="alldata.applyStatus == 1"
        class="margin-top"
        :column="7"
        size="medium"
        border
      >
        <el-descriptions-item :span="3" label-class-name="my-labels">
          <template slot="label"> 授信申请编号： </template>
          {{ applydata.applyCode }}
        </el-descriptions-item>
        <el-descriptions-item :span="4" label-class-name="my-labels">
          <template slot="label"> 额度状态： </template>
          {{ applydata.applyStatus == 1 ? "审核通过" : "已拒绝" }}
        </el-descriptions-item>
        <el-descriptions-item
          v-if="alldata.applyStatus == 1"
          :span="3"
          label-class-name="my-labels"
        >
          <template slot="label"> 授信额度： </template>
          {{ applydata.creditLine }}
        </el-descriptions-item>
        <el-descriptions-item
          v-if="alldata.applyStatus == 1"
          :span="4"
          label-class-name="my-labels"
        >
          <template slot="label"> 可用额度： </template>
          {{ applydata.availableQuota }}
        </el-descriptions-item>
        <el-descriptions-item
          v-if="alldata.applyStatus == 1"
          :span="3"
          label-class-name="my-labels"
        >
          <template slot="label"> 授信起始日期： </template>
          {{ applydata.creditStartTime.replace("T", " ") }}
        </el-descriptions-item>
        <el-descriptions-item
          v-if="alldata.applyStatus == 1"
          :span="4"
          label-class-name="my-labels"
        >
          <template slot="label"> 授信结束日期： </template>
          {{ applydata.creditEndTime.replace("T", " ") }}
        </el-descriptions-item>
      </el-descriptions>
    </div>
    <div class="header_title" v-if="alldata.applyStatus == 1">借款记录</div>
    <div class="table" v-if="alldata.applyStatus == 1">
      <el-table :data="alldata.borrowFundsRecords" border style="width: 100%">
        <el-table-column
          prop="iouNo"
          align="center"
          show-overflow-tooltip
          label="借据编号"
        >
        </el-table-column>
        <el-table-column prop="loanAmount" align="center" label="贷款金额">
        </el-table-column>
        <el-table-column
          prop="repaymentType"
          :formatter="repaymentType"
          align="center"
          label="还款方式"
        >
        </el-table-column>
        <el-table-column
          prop="repaymentDueTime"
          align="center"
          :formatter="changedata"
          label="贷款到期日"
        >
        </el-table-column>
        <el-table-column
          prop="loanStatus"
          :formatter="loanStatusf"
          align="center"
          label="贷款状态"
        >
        </el-table-column>
        <el-table-column
          prop="issueLoanStatus"
          :formatter="issueLoanStatus"
          align="center"
          label="放款状态"
        >
        </el-table-column>
        <el-table-column align="center" label="操作" width="280px">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              round
              class="button"
              @click="handleDelete(scope.row)"
              >我要还款</el-button
            >

            <el-button
              size="mini"
              type="primary"
              round
              class="button"
              @click="LookaGreement(scope.row)"
              >查看签署协议</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="header_title" v-if="alldata.applyStatus == 1">还款记录</div>
    <div class="table" v-if="alldata.applyStatus == 1">
      <el-table
        :data="alldata.repaymentResultNotices"
        border
        style="width: 100%"
      >
        <el-table-column
          prop="repyAplyNo"
          align="center"
          show-overflow-tooltip
          label="还款申请编号"
        >
        </el-table-column>
        <el-table-column
          prop="createTime"
          :formatter="changedata"
          align="center"
          label="还款日期"
        >
        </el-table-column>
        <el-table-column prop="repyAmt" align="center" label="本次还款金额">
        </el-table-column>
        <el-table-column prop="repyPnp" align="center" label="本次还款本金">
        </el-table-column>
        <el-table-column prop="repyInt" align="center" label="本次还款利息">
        </el-table-column>
        <el-table-column prop="repyPny" align="center" label="本次还款罚息">
        </el-table-column>
        <el-table-column
          prop="repyStatus"
          :formatter="typeFormatter"
          align="center"
          label="还款状态"
        >
        </el-table-column>
        <el-table-column prop="updInt" align="center" label="未还利息">
        </el-table-column>
        <el-table-column prop="updPnp" align="center" label="未还本金">
        </el-table-column>
        <el-table-column prop="updPny" align="center" label="未还罚息">
        </el-table-column>
      </el-table>
    </div>
    <div class="table"></div>
    <div class="header_title">关联的服务信息</div>
    <div class="info">
      <div class="relation">
        <div class="left pointer" @click="todetail">
          <div class="img">
            <img class="img" :src="alldata.mainGraph" />
          </div>
          <div class="every">
            <div class="first">{{ alldata.name }}</div>
            <div class="secend">
              服务类别：{{ alldata.serviceType }} | 单价：{{
                alldata.goodsPrice + alldata.unit
              }}
            </div>
            <div class="thired">{{ alldata.createTime }}</div>
          </div>
        </div>
        <div class="right">
          <div class="tag">{{ alldata.onShelf ? "销售中" : "已下架" }}</div>
        </div>
      </div>
    </div>

    <el-dialog title="签署信息:" :visible.sync="dialogVisible" width="60%">
      <el-table :data="agreementTable" border style="width: 100%">
        <el-table-column
          prop="contractNo"
          align="center"
          show-overflow-tooltip
          label="合同编号"
        >
        </el-table-column>
        <el-table-column
          prop="ctrName"
          align="center"
          show-overflow-tooltip
          label="合同名称"
        >
        </el-table-column>
        <el-table-column
          prop="signStCd"
          align="center"
          show-overflow-tooltip
          label="合同状态"
        >
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              round
              class="button"
              @click="LookaRow(scope.row)"
              >查看</el-button
            >
            <el-button
              size="mini"
              type="primary"
              round
              class="button"
              @click="download(scope.row)"
              >下载</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import { detailServiceOrder, listContract } from "@/api/home";
const { baseUrl } = require("@/config/index.js");
export default {
  data() {
    return {
      dialogVisible: false,
      applyStatus: ["待处理", "已通过", "已拒绝"],
      agreementTable: [],
      alldata: {
        auditTime: "",
        creditStartTime: "",
        creditEndTime: "",
      },
      applydata: {
        auditTime: "",
      },
      srcList: [
        "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
        "https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg",
        "https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg",
      ],
    };
  },
  mounted() {
    this.getdetail();
  },
  methods: {
    changedata(row, column, cellValue, index) {
      return (cellValue = cellValue ? cellValue.split("T")[0] : "");
    },
    issueLoanStatus(row) {
      switch (row.issueLoanStatus) {
        case 0:
          return "已放款";
        case 1:
          return "放款失败";
        case 2:
          return "未放款";
        default:
          return "";
      }
    },
    typeFormatter(row) {
      switch (row.repyStatus) {
        case "1":
          return "还款成功";
        case "2":
          return "还款失败";
        default:
          return "";
      }
    },
    loanStatusf(row) {
      switch (row.loanStatus) {
        case 0:
          return "未结清";
        case 1:
          return "已结清";
        default:
          return "";
      }
    },
    repaymentType(row) {
      switch (row.repaymentType) {
        case "0":
          return "利随本清";
        case "5":
          return "初期付息";
        default:
          return "";
      }
    },
    download(e) {
      window.open(e.downUrl);
    },
    LookaRow(e) {
      window.open(e.viewUrl);
    },
    LookaGreement(e) {
      console.log(e);
      listContract(e.iouNo).then((res) => {
        console.log(res);
        if (res.code == 0) {
          let list = res.data.ctrSigns;
          for (let i = 0; i < list.length; i++) {
            if (list[i].signStCd == "2") {
              list[i].signStCd = "待客户签署";
            }
            if (list[i].signStCd == "3") {
              list[i].signStCd = "待平台签署";
            }
            if (list[i].signStCd == "4") {
              list[i].signStCd = "待资金方签署";
            }
            if (list[i].signStCd == "5") {
              list[i].signStCd = "签署完成";
            }
            if (list[i].signStCd == "6") {
              list[i].signStCd = "已拒签";
            }
            if (list[i].signStCd == "7") {
              list[i].signStCd = "待合作机构签署";
            }
            if (list[i].signStCd == "8") {
              list[i].signStCd = "待核心企业签署";
            }
          }
          this.agreementTable = list;
          this.dialogVisible = true;
        } else {
          this.$message({
            type: "error",
            message: res.data.msg,
          });
        }
      });
    },
    handleDelete() {
      this.$confirm(
        "请保证您在天山村镇银行开设的还款账户余额充足，到期后会自动扣款。",
        "提示",
        {
          confirmButtonText: "已知悉",
          cancelButtonText: "取消",
          type: "warning",
          customClass: "zzye-confirm",
        }
      )
        .then(() => {
          var arr = document.getElementsByClassName("el-message-box");
          console.log(arr);
          // this.$message({
          //   type: 'success',
          //   message: '删除成功!'
          // });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    getdetail() {
      detailServiceOrder(this.$route.query.id).then((res) => {
        if (res.code == 0) {
          this.alldata = {
            ...res.data.serviceOrderVO,
            ...res.data.finaceInfo,
            mainGraph:
              baseUrl +
              "third/third/ossGetFile/" +
              res.data.serviceOrderVO.mainGraph,
            goodsPrice: res.data.serviceOrderVO.goodsPrice
              ? res.data.serviceOrderVO.goodsPrice
              : "面议",
            createTime: res.data.serviceOrderVO.createTime.replace("T", " "),
            auditTime: res.data.serviceOrderVO.auditTime
              ? res.data.serviceOrderVO.auditTime.replace("T", " ")
              : "",
          };
          this.applydata = {
            ...res.data.applyQuota,
            receiptInvoiceUrl:
              baseUrl +
              "third/third/ossGetFile/" +
              res.data.applyQuota.receiptInvoiceUrl,
            businessLicenseUrl:
              baseUrl +
              "third/third/ossGetFile/" +
              res.data.applyQuota.businessLicenseUrl,
            applyDate: res.data.applyQuota.applyDate.replace("T", " "),
            registerTime: res.data.applyQuota.registerTime
              ? res.data.applyQuota.registerTime.replace("T", " ")
              : "",
          };
        }
      });
    },
    todetail() {
      this.$router.push({
        path: "/associateddetail",
        query: {
          id: this.alldata.serviceGoodId,
          type: "other",
        },
      });
    },
  },
};
</script>
<style lang="scss">
.zzye-confirm {
  .el-message-box {
    width: 515px !important;
    height: 243px !important;
    background: #ffffff !important;
    border-radius: 10px !important;
  }
  .el-message-box__header {
    background: #f8f8f8;
  }
  .el-message-box__title {
    font-size: 16px;
    font-weight: 400;
    color: #666666;
  }
  .el-message-box__content {
    padding: 49px 105px;
    color: #606266;
    font-size: 14px;
    height: 147px;
  }
  .el-message-box__btns {
    .el-button--primary {
      background: linear-gradient(90deg, #2079fb, #4cb2fc);
    }
  }
}

.zyq_dialog {
  .el-dialog {
    border-radius: 10px;
  }
  .el-dialog__header {
    height: 53px;
    padding: 19px 25px;
    background: #f8f8f8;
    border-radius: 10px;
    .el-dialog__title {
      font-size: 16px;
      line-height: 16px;
      color: #666666;
    }
  }
  .el-dialog__footer {
    padding: 18px 25px 25px;
    .el-button--primary {
      background: linear-gradient(90deg, #2079fb, #4cb2fc);
    }
  }
  // input[type=text] {
  //   height: 34px;
  // }
  .demo-dynamic {
    width: 100%;
  }
  .el-input {
    width: 211px;
  }
  .dialog_center {
    display: flex;
    justify-content: center;
  }
}
</style>
<style>
.my-labels {
  width: 284px;
  text-align: right !important;
}
.my-label {
  width: 176px;
  text-align: right !important;
}
.label_right {
  width: 205px;
  text-align: right !important;
}
</style>
<style lang="scss" scoped>
.button {
  width: 100px;
  height: 24px;
  line-height: 24px;
  padding: 0;
}
.center {
  width: 1195px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-shadow: -1px 0px 10px 0px rgba(0, 0, 0, 0.05);
  margin-bottom: 20px;
  .info {
    .relation {
      display: flex;
      justify-content: space-between;
      padding: 17px;
      .left {
        display: flex;
        .img {
          width: 167px;
          height: 97px;
          border-radius: 2px;
        }
        .every {
          padding-left: 17px;
          .first {
            height: 20px;
            font-size: 20px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #40474e;
          }
          .secend {
            height: 15px;
            font-size: 14px;
            margin: 13px 0 31px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #40474e;
          }
          .thired {
            height: 9px;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #787b80;
          }
        }
      }
      .right {
        .tag {
          width: 68px;
          height: 22px;
          text-align: center;
          line-height: 20px;
          color: #188827;
          border: 1px solid #188827;
          border-radius: 2px;
        }
      }
    }
  }
  .header_title {
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #000000;
    padding-left: 15px;
    height: 45px;
    line-height: 45px;
    background: #fafafa;
    border-top: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
    .red_title {
      font-size: 12px;
      color: #ff0000;
    }
  }
  .table {
    padding: 18px 30px;
  }
  .company {
    padding: 23px 13px 20px;
    .name {
      font-size: 18px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #333333;
    }
    .tell {
      margin-top: 15px;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #666666;
    }
  }
  .order {
    display: flex;
    padding: 20px 0;
    .left {
      width: 267px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .table {
      width: 900px;
      padding-top: 14px;
    }
  }
  .car {
    padding: 23px 112px;
  }
}
</style>>